<template>
  <div class="view-ebooks container py-5">
    <action-dispatcher action="ebooks/getAll">
      <div class="row g-4">
        <div
          v-for="ebook in ebooks"
          class="col-lg-3 col-md-4 col-6"
          :key="ebook.id"
        >
          <router-link
            :to="{ name: 'ebook', params: { id: ebook.id, slug: ebook.slug } }"
            class="text-decoration-none text-light"
          >
            <div class="position-relative">
              <div class="ratio">
                <img :src="ebook.cover" class="img-fluid" />
              </div>
              <div v-if="!ebook.progress?.finished_at" class="progress">
                <div
                  class="progress-bar"
                  :style="{ width: progress(ebook) + '%' }"
                ></div>
              </div>
              <div v-if="ebook.progress?.finished_at" class="finished badge">
                <i class="fa-solid fa-check"></i> LIDO
              </div>
            </div>
            <h4 class="font-serif my-2">{{ ebook.title }}</h4>
            <small class="text-secondary">{{
              ebook.authors.map((a) => a.name).join(", ")
            }}</small>
          </router-link>
        </div>
      </div>
    </action-dispatcher>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { get } from "lodash";

export default {
  computed: {
    ...mapState("ebooks", ["ebooks"]),
  },
  methods: {
    progress(ebook) {
      return (get(ebook, "progress.stopped_at") || 0) / 10;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.view-ebooks {
  .ratio {
    --bs-aspect-ratio: 141.4%;
  }

  .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 0;
    height: 4px;
    background-color: transparent;
  }

  .finished {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: $success;
    color: white;
  }
}
</style>
